import React, { useState } from "react";
import { Link } from "react-router-dom";
import BrandLogo from "../assets/icons/tb-logo-web.png"

const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="bg-white lg:text-gray-800 p-4 lg:px-10 lg:flex justify-between items-center lg:border-b-1 mx-auto">
      <div className="flex items-end justify-between">
        <Link to="/">
          <img src={BrandLogo} alt="Logo" className="h-14" />
        </Link>
        <div className="lg:hidden w-12">
          <button
            className="flex-row items-end text-gray-600 focus:outline-none mr-2"
            onClick={toggleMenu}
          >
            {menuOpen ? (
              <svg
                className="w-6 h-6 text-gray-800"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            ) : (
              <svg
                className="w-6 h-6 text-gray-800"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            )}
          </button>
        </div>
      </div>

      <nav
        className={`${menuOpen ? "flex" : "hidden"
          } lg:flex flex-col lg:flex-row lg:mr-8 mt-4 lg:mt-0`}
      >
        <ul
          className={`lg:flex lg:flex-row flex-col lg:items-center lg:space-x-16 text-gray-800 lg:text-gray-900 mr-12 ${menuOpen ? "block" : "hidden"
            }`}
        >
          <li className="contrast-more:border-slate-400">
            <Link to="/" className="cursor-pointer">
              Home
            </Link>
          </li>
          <li>
            <Link to="about" className="cursor-pointer">
              About
            </Link>
          </li>
          <li className="lg:mx-12">
            <Link to="contact" className="cursor-pointer">
              Contact
            </Link>
          </li>
        </ul>

        <div className="lg:flex lg:flex-row lg:items-center flex-col lg:space-x-4 mt-4 lg:mt-0">
          <select className="border rounded px-2 py-1 mb-2 lg:mb-0 text-gray">
            <option>Language</option>
            <option>Spanish</option>
            <option>English</option>
          </select>
          <div className="flex flex-col lg:flex-row md:items-center">
            {/* <button className="border-2 hover:bg-[#002BE4] hover:text-white text-black px-4 py-2 rounded mb-2 lg:mb-0">
              <Link to="/registerb2b">Register as B2B</Link>
            </button> */}
            <button className="bg-[#0030FC] hover:bg-[#002BE4] text-white px-4 py-2 rounded lg:ml-5">
              <Link to="/driver">Become a Driver</Link>
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
