import React from "react";
import { Link } from "react-router-dom";

interface HelpContentProps {
  content: string;
}

const HelpContent: React.FC<HelpContentProps> = ({ content }) => {
  return (
   
    <>
      <div
      className="help-content"
      dangerouslySetInnerHTML={{ __html: content }}
      />

      <div>
    
      <div className="flex flex-col lg:flex-row md:items-center mt-4">
      <button className="border-2 hover:bg-[#002BE4] hover:text-white text-black px-4 py-2 rounded mb-2 lg:mb-0">
        <Link to="/login">Login to get help</Link>
      </button>
    </div>
    </div>
    </>
  );
};

export default HelpContent;
