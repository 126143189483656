import { useState, FormEvent } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";


const LoginPage = () => {
  const [firstCallSuccess, setCallSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      if (!firstCallSuccess) {
        const response = await axios.delete("https://api.transportbidder.com/api/v1/accounts", {
          headers: {
            'Content-Type': "multipart/form-data"
          },
          data: {
            email: email,
            password: password
          }
        });
        if (response.status === 200) {
          // Handle success
          console.log("DELETE Success")
          setCallSuccess(true);
        }
      } else {
        const response = await axios.delete("https://api.transportbidder.com/api/v1/accounts", {
          headers: {
            'Content-Type': "multipart/form-data"
          },
          data: {
            email: email,
            password: password,
            code: code
          }
        });

        if (response.status === 200) {
          setCallSuccess(false);
          alert('Account successfully scheduled for deletetion.');
          navigate("/home")
        }

        if (response.status === 409) {
          alert('Account is already scheduled for deletetion.');
          navigate("/home")
        }
      }
    } catch (error) {
      if (error.response.status === 409) {
        alert('Account is already scheduled for deletetion.');
        navigate("/home")
        return;
      }
      if (firstCallSuccess) {
        alert("Please provide correct OTP");
      } else {
        alert("Please provide correct email and password");
      }
    }
  };

  return (
    <>
      <div>
      </div>
      <div className="gap-8 md:grids-cols-2 lg:flex justify-between lg:px-10 px-2 lg:mx-10 mx-2 lg:my-12 p-2 overflow-x-auto mx:auto">

        <div className="lg:w-[600px] mx-auto p-6 bg-white rounded-lg">
          <form onSubmit={handleSubmit}>
            {!firstCallSuccess && (
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            )}
            {!firstCallSuccess && (
              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
            )}
            {firstCallSuccess && (
              <div className="mb-4">
                <label
                  htmlFor="code"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Please enter OTP sent to your registered email
                </label>
                <input
                  type="text"
                  id="code"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  required
                />
              </div>
            )}
            <button
              type="submit"
              className="w-full bg-blue-500 text-white font-semibold py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
