import React from "react";
import { Link as ScrollLink } from "react-scroll";
import { useNavigate } from "react-router-dom";
import BrandLogoWhite from "../assets/icons/tb-white-logo-web.png";

type ItemType = {
  heading: string;
  items: { list: string; path: string }[];
};

const footerItems: ItemType[] = [
  {
    heading: "About",
    items: [
      { list: "Home", path: "/home" },
      { list: "About", path: "/about" },
      { list: "Contact", path: "/contact" },
    ],
  },
  {
    heading: "Quick Link",
    items: [
      { list: "Privacy Policy", path: "/privacy" },
      { list: "Term & Condition", path: "/privacy" },
      { list: "Delete Account", path: "/help" },
    ],
  },
];

const Footer: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <div>
      <footer className="footer p-10 bg-black text-white lg:h-[300px]">
        <aside>
          <a href="/">
            <img src={BrandLogoWhite} alt="tb logo" />
          </a>
          <p className="lg:w-[300px] lg:ms-10">
            Book your ride hassle-free with TransportBidder. Safe, reliable, and
            diverse options tailored to your needs.
          </p>
        </aside>

        {footerItems.map((footerSection, index) => (
          <nav key={index}>
            <h3 className="footer-title">{footerSection.heading}</h3>
            <ul>
              {footerSection.items.map((listItem, itemIndex) => (
                <li className="cursor-pointer mt-2" key={itemIndex}>
                  {listItem.path.startsWith("/") ? (
                    <a
                      onClick={() => handleNavigation(listItem.path)}
                      style={{ cursor: "pointer", color: "inherit" }}
                    >
                      {listItem.list}
                    </a>
                  ) : (
                    <ScrollLink
                      to={listItem.path}
                      smooth={true}
                      duration={800}
                      offset={-70}
                    >
                      {listItem.list}
                    </ScrollLink>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        ))}

        <nav>
          <h6 className="footer-title">Social Media</h6>
          <div className="grid grid-flow-col gap-4">
            <a href="#">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="fill-current"
              >
                <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
              </svg>
            </a>
            <a href="#">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="fill-current"
              >
                <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
              </svg>
            </a>
            <a href="#">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="fill-current"
              >
                <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path>
              </svg>
            </a>
          </div>
        </nav>
      </footer>
      <hr />
      <div className="mx-auto flex flex-col text-center py-3 bg-black text-white">
        <p>
          Privacy Policy | Transportbidder’s Privacy Policy | All Rights
          Reserved @Transportbidder | Developed by{" "}
          <a href="https://www.krshnatech.com/" className="text-[#B0B0B0]">
            Krshna Technology
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
