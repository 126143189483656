import React, { useState } from "react";
import CategoryList from "../components/CategoryList";
import PrivacyPolicyContent from "../components/PrivacyPolicyContent";
import PrivacyPolicyImage from "../assets/img/privacy-policy-tb.webp";

const categories = [
  "Privacy Policy",
  "General Conditions of Use",
  "Contact Information",
];

type Contents = {
  [key: string]: string;
};

const contents: Contents = {
  "General Conditions of Use": `
    <h3><b>Introduction</b></h3>
    <p>These General Conditions of Use regulate access and use of the Transportbidder mobile application and website (hereinafter, the "Platform"), property of Transportbidder.com S.A.C, with RUC Nº 20612375675 and address at San Juan de Dios Housing Association, Block H, Lot 10, San Martín de Porres District, Lima Province and Department, Perú, (hereinafter, "Transportbidder " or the "Company"). The Platform is directed only to users residing in Peru. By accessing and using this Platform, you (hereinafter, the "User") acknowledge that you have read, understood and accepted these Terms and Conditions, which may be modified at any time by Transportbidder .</p>
    </br>
    <h3><b>Object</b></h3>
    <p>Through the Platform, Transportbidder provides Users with a vehicle reservation service with a driver, as well as requesting shipping services using motorized vehicles (hereinafter, the "Service"). The Company reserves the right to modify the Platform, its functionalities and conditions of use.</p>
    </br>
    <h3><b>Access and use</b></h3>
    <h2>Access requirements</h2>
    <p>Access to the Platform is limited to those over 18 years of age.</p>
    </br>
    <h2><b>Proper use of the Platform</b></h2>
    <p>The User acknowledges that access and use of the Platform is carried out under his or her exclusive responsibility. Transportbidder will not be responsible for any improper use that the User or third parties make of the Platform, nor for any damages that may arise from the same. The User agrees to use the Platform in accordance with applicable legislation, morality, good customs and public order.</p>
    </br>
    <h1><b>Registration and payment method</b></h1>
    <h2><b>Registration process</b></h2>
    <p>Access to the Platform will be subject to the User's prior registration.</p>
    </br>
    <h2><b>User data</b></h2>
    <p>The data provided by the User must be exact, current and truthful.</p>
    </br>
    <h2><b>Password and security</b></h2>
    <p>Access will be through a password, with the User being responsible for its custody and improper use.</p>
    </br>
    <h2><b>Payment methods</b></h2>
    <p>The rates applicable to the Service will be charged to the User at the end of the journey, which may be done by credit card or other enabled means. Transportbidder reserves the right to modify, expand or eliminate any payment method.</p>
    </br>
    <h1><b>Intellectual property</b></h1>
    <p>The User acknowledges that all intellectual and industrial property rights over the Platform and its contents belong to Transportbidder. Any unauthorized use of said content is prohibited.</p>
    </br>
    <h1><b>Data Protection</b></h1>
    <h2><b>Processing of personal data</b></h2>
    <p>In order to provide the messaging service and process payments, certain Personal Data relating to your identity and payment methods will be collected, in compliance with data protection laws.</p>
    </br>
    <h2><b>Privacy Policy</b></h2>
    <p>Users accept that their data will be treated according to the Terms and Conditions and in accordance with the Privacy Policy. This Policy regulates the use and protection of users' personal data by Transportbidder .</p>
    </br>
    <h1><b>Exclusion of guarantees and responsibility</b></h1>
    <h2><b>Platform Availability</b></h2>
    <p>Transportbidder does not guarantee the availability and continuity of the Platform's operation, nor the absence of viruses or other elements that may cause alterations in the User's systems.</p>
    </br>
    <h2><b>Limitation of liability</b></h2>
    <p>The Company will not be responsible for damages resulting from interruptions, computer failures, computer attacks by third parties or unauthorized access to the Platform.</p>
    </br>
    <h1><b>Notifications</b></h1>
    <h2><b>Transportbidder Notifications</b></h2>
    <p>The user authorizes Transportbidder to access the image, audio devices and other elements related to said services, as well as to record and store data related to the provision of its services.</p>
    </br>
    <h2><b>User Notifications</b></h2>
    <p>The User may notify Transportbidder through the channels enabled for this purpose.</p>
    </br>
    <h1><b>Applicable law and jurisdiction</b></h1>
    <p>These Terms and Conditions will be governed and interpreted in accordance with Peruvian legislation. Any controversy will be resolved by the courts and tribunals of the city of Lima, Peru.</p>
  `,

  "Privacy Policy": `
    <p>Last update: June 19, 2024<br>
At Transportbidder we take the protection of your privacy very seriously. This Privacy Policy explains how we collect, use, share and protect the personal information we obtain when you use our mobile application, website and transportation services in Peru. We recommend that you read this document carefully to understand our privacy practices.</p>
<h3><b>1.	Data Processor</b></h3>
<p>Transportbidder is responsible for the processing of your personal data. For any questions or exercise of your rights, you can contact us at the following address:
•	Transportbidder SAC
•	Address: San Juan de Dios Housing Association, Block H, Lot 10, San Martín de Porres District, Lima Province and Department, Perú
•	Email: ayuda@transportbidder.com
</p>
<h4><b>2.	Information we collect</b></h4>
<p>We may collect the following categories of personal information:</p>
<h4><b>2.1.	Information provided by users</b></h4>
<p>a) Passenger registration information:
•	Names and surnames
•	Telephone numbers
•	Email addresses
•	Country and city of residence
If legally required, we may request a profile photograph for verification, national identification numbers for security purposes, as well as emergency contact numbers.
b) Driver information:
•	Full names and surnames
•	profile photo
•	Driver's license information and status
•	Vehicle details (make, model, year, color, license plate, inspection reports, images)
•	Identity documents (ID, passport, residence permits)
•	Date of birth, gender
•	Tax or tax identification numbers
•	Insurance information
•	Driving history
•	Banking or payment details
•	Any other documentation required by applicable regulations.

Where permitted or required by law, we may request information about criminal or other records.
c) User-generated content:
Comments, ratings and reviews that you decide to upload about our services.
d) Communications with Transportbidder :
•	Chat messages, emails with attachments
•	Recordings of telephone calls and their related metadata
e) Information for promotional programs:
To participate in incentive programs, referrals or other promotions, we may request names, emails, phone numbers, payment information, addresses and vehicle details .</p>
<h4><b>2.2.	Information collected automatically</b></h4>
<h4>a) <b>Location Data</b>:</h4>
<h4><b>Driver</b></h4> <p>TBidder collects location data when the app is running in the foreground (app is open and visible to user) or background (app not visible to user or user has pressed home button) in your mobile device. When the app operates in Driver Mode, the app collects location data only when the driver has set the online status to active “Go”.</p>
</br>
<h4><b>Passenger</b>: </h4> 
<p>We collect precise or approximate location data from your Device if you enable us to do so. from service requested to completion of the trip, and any time the app is running in the foreground of your Device. We use this data to enhance the use of TBidder, including to improve pick-up locations, enable safety features, and prevent and detect fraud.
</br>
</br>
<p>b) Transaction information:
Amounts charged, payment data, date/time of services, routes and distances traveled.
</br>
</br>
c) Usage information:
Dates and times of access, functions and pages visited, application failures and other activities.
Data related to third-party services used before Transportbidder .
</br>
</br>
d) Communications information:
Time, date and content of communications between users and with Transportbidder , for assistance and dispute resolution .
</br>
</br>
e) Device information:
Model, manufacturer, user agent, IP address, operator, network type, time zone, language, advertising identifiers, browser, operating system, display parameters, battery status, installed applications.
Data from mobile sensors such as speed, direction, altitude, acceleration, etc.
f) Automatically generated user and trip IDs .
g) Cookies and tracking technologies, according to our Cookies Policy.</p>
<h4>2.3.	Information from other sources<h4>
<p>a) Services from third party providers.
b) Government authorities and security forces, as required by law.
c) Other users, such as information related to incidents, claims, audio, video, images or documents.</p>
<h4><b>3.	How we use information</b></h4>
We use the personal information collected for the following purposes:
- Create and manage user accounts (passengers and drivers).
- Provide, maintain and improve our transportation services and application functions.
- Process payments, manage invoices and reconcile transactions.
- Verify driver identities, eligibility and documentation in accordance with legal and regulatory requirements.
- Communicate with users about trips, incidents, updates and any other relevant information.
- Prevent, detect and investigate fraudulent activities, illegal conduct or violations of our policies.
- Carry out analysis, surveys, studies and develop new products to improve our services.
- Comply with applicable legal and regulatory obligations.
- Send marketing and promotional communications about our services, only if the user previously authorizes it.
<h4><b>4.	How we share information</b></h4>
We may share personal information with the following entities:
- Passengers and drivers, to the extent necessary to facilitate transportation services.
- Service providers and business partners who help us operate the application, process payments, verify identities, manage infrastructure, among others.
- Government authorities, law enforcement and regulators when there is a legal requirement.
- Other companies in the Transportbidder business group for legitimate administrative and operational purposes.
- Third parties involved in a merger, acquisition or other corporate transaction related to our company.
- Third parties, only with the user's prior consent or when legally required.

<h5><b>5.	User rights</b><h5/>
In accordance with applicable Peruvian legislation, users have the right to:
- Access your personal data that we have.
- Request the rectification of inaccurate or incomplete data.
- Request the cancellation and deletion of your personal data from our records.
- Oppose the processing of your personal data for legitimate reasons.
- Request the portability of your personal data to another service provider.
Users may exercise these rights by sending a written request to [email address and/or physical address].

<h5><b>6.	Security of the information</b></5>
We implement reasonable technical, physical and administrative measures to protect personal information from loss, misuse, unauthorized access, disclosure or modification. Our security measures include encryption, access controls, firewalls, monitoring and other industry standard technologies. However, no system is 100% secure, so we cannot guarantee the absolute security of the data we process.

<h5><b>7.	International data transfers</b></h5>
In the event of transferring personal data outside of Peru, we will adopt appropriate measures and safeguards, such as standard contractual clauses, to guarantee a level of protection in accordance with Peruvian law.

<h5><b>8.	Data retention</b></h5>
We will retain users' personal information only for as long as necessary for the purposes described in this Privacy Policy, unless a longer retention period is required or permitted by law. Specific retention periods will depend on the nature of the information and the context of its collection and use.

<h5><b>9.	Links to third parties</b></h5>
Our application and website may contain links to third-party sites or services. This Privacy Policy does not apply to such third parties, so we recommend that you review the privacy policies of those sites before providing any personal information.

<h5><b>10.	Changes to the Privacy Policy</b></h5>
We reserve the right to modify this Privacy Policy at any time. In case of material changes, we will publish the new version on our application, website and notify you through the appropriate communication channels.

<h5><b>11.	Contact</b></h5>
If you have questions, concerns or requests related to this Privacy Policy or our privacy practices, you may contact us at ayuda@transportbidder.com.
By using our services, you accept the practices described in this Privacy Policy. If you do not agree with any of these practices, we recommend that you do not use our application or our services.

  `,

  "Contact Information": `
    <h1>Contact Information</h1>
    <p><b>Address:</b> Asociación de Vivienda San Juan de Dios Mz. H lote 10 San Martin de Porres
      <br />
      <b>Email:</b> help@transportbidder.com</p>
  `,
};

const PrivacyPolicyPage: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<string>(
    categories[0]
  );

  return (
    <div className="p-6">
      <img
        src={PrivacyPolicyImage}
        className="w-full h-[350px] object-cover mb-6"
        alt="Privacy Policy"
      />
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-4">
        Privacy Policy
      </h1>
      <div className="flex flex-col md:flex-row">
        <CategoryList
          categories={categories}
          selectedCategory={selectedCategory}
          onSelectCategory={setSelectedCategory}
        />
        <div className="flex-1 ml-0 md:ml-4 mt-4 md:mt-0 lg:mx-12">
          <PrivacyPolicyContent content={contents[selectedCategory]} />
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
