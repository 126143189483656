import React from "react";
import GooglePlay from "../assets/icons/google-play.svg";
import AppStore from "../assets/icons/app-store.svg";
import BannerImage from "../assets/img/banner-image.webp";
import CarRoad from "../assets/img/car_road.svg";
import StarBig from "../assets/icons/star-big.svg";

const Banner: React.FC = () => {
  return (
    <div>
      <div className="gap-8 md:grids-cols-2 lg:flex justify-between items-center lg:px-10 px-2 lg:mx-10 mx-2 lg:p-10 p-2 ">
        {/* Left Side Banner Start */}
        <div>
          <div className="mt-10 lg:text-left">
            <p className="w-[300px] flex flex-row justify-center  bg-slate-100 bottom-2 rounded-sm p-2 px-4 mx-auto lg:mx-0">
              <img src={StarBig} alt="" className="h-2" />
              Ride and Transportation Booking Made Simple and Reliable
            </p>
          </div>
          <div className="lg:py-6 py-3">
            <h1 className="lg:text-4xl text-2xl font-semibold text-center lg:text-left">
              Your Ultimate On-Demand <br />
              Transport & Delivery Solution
            </h1>
          </div>
          <div className="lg:py-6 p-3 text-center lg:text-left">
            <p className="lg:w-[550px]">
              Book cabs, ambulances, bikes, and more instantly with our
              app. Get fast, safe, and reliable service for all your transport
              and delivery needs, anytime, anywhere. Trusted by thousands for
              its efficiency.
            </p>
          </div>
          <div className="flex flex-row gap-2 justify-center lg:justify-start text-black py-2 mx-auto">
            <button className="py-2 rounded">
              <img
                src={GooglePlay}
                alt="download tb"
                className="h-[100px] lg:w-[180px]"
              />
            </button>
            <button className="px-4 py-2 rounded">
              <img
                src={AppStore}
                alt="download tb"
                className="h-[100px] lg:w-[180px]"
              />
            </button>
          </div>
        </div>
        {/* Left Side Banner End */}

        {/* Right Side Banner Start */}
        <div>
          <img src={BannerImage} alt="Transportbidder Banner" className="" />
        </div>
        {/* Right Side Banner End */}
      </div>
      <div>
        <img src={CarRoad} alt="" className="w-full" />
      </div>
    </div>
  );
};
export default Banner;
