import React, { useState } from "react";
import CategoryList from "../components/CategoryList";
import HelpContent from "../components/HelpContent";

const categories = [
  "Delete Account",
];

type HelpContents = {
  [key: string]: string;
};

const HelpContents: HelpContents = {
  "Delete Account": `
    <h3><b>Delete TBidder Account</b></h3>
    <p>You can delete your account from the app or the web.

    When you begin, we’ll ask you to verify your identity using a temporary verification code. This may require you to have a phone number attached to your account, so please visit our I can’t update my phone number or email help page if you’re unable to add a phone number in your account settings.

If you have an TBidder or Driver account using the same account details, it will also be deleted when your request is complete.</p>
    </br>
    <h3><b>Delete your account using the app</b></h3>
    <p>1. Click <span>&#x2630;</span> to open side menu </br>
    2. Select <b>Settings</b> </br>
    3. Scroll down and tap <b>Account Deletion</b> </br>
    4. Follow the remaining steps in the app to complete the process</p> </br>
    </br>
    <h3><b>Delete your account using the web</b></h3>
    <p><a href="/delete-account"><b>Click Here</b> </a>and You’ll be guided through the steps to delete your account.</p>
    </br>
    <h3><b>Notes on deleting your account</b></h3>
    
    <p>After completing either process above, your account will be immediately deactivated and then permanently deleted after 30 days. Any unused credits, promotions, or rewards will be removed. If you sign back into your account during this period, your account will be restored.

In accordance with our Privacy Notice, we may retain certain information about you as required by law, or for legitimate business purposes, to the extent permitted by law.</p>
  `,

};

const HelpPage: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<string>(
    categories[0]
  );

  return (
    <div className="p-6">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-4">
        Account
      </h1>

      <div className="flex flex-col md:flex-row">
        <CategoryList
          categories={categories}
          selectedCategory={selectedCategory}
          onSelectCategory={setSelectedCategory}
        />
        <div className="flex-1 ml-0 md:ml-4 mt-4 md:mt-0 lg:mx-12">
          <HelpContent content={HelpContents[selectedCategory]} />
        </div>
      </div>

    </div>
  );
};

export default HelpPage;
